<template>
    <div class="order_wrap column_start">
        <div class="order_nav flex_start">
            <div :class="['order_nav_item',order_status == item.status?'order_nav_active':'']" v-for="(item,index) in order_navs" :key="index" @click="changeNav(item.status,item.name)">{{item.name}} {{item.num}}</div>
        </div>
        <div class="order_list">
            <div v-if="orderList.length > 0">
                <div class="order_info" v-for="(item,index) in orderList" :key="item.order_sn">
                    <div class="order_title flex_between_center">
                        <div class="suc_lf flex_start_center" v-if="item.order_info.order_status>=20&&item.order_info.order_status<50">
                            {{item.order_info.trip_type==1?'实时订单':'预约订单'}} {{item.order_info.add_time | timestampToTime}}
                            <el-tag effect="dark" size="mini" v-if="item.order_info.order_status==20">已接单</el-tag>
                            <el-tag effect="dark" size="mini" v-else-if="item.order_info.order_status==30">运输中</el-tag>
                            <el-tag effect="dark" size="mini" v-else-if="item.order_info.order_status==31">待签收</el-tag>
                            <el-tag effect="dark" size="mini" v-else-if="item.order_info.order_status==40">已完成</el-tag>
                            <el-tag effect="dark" size="mini" v-else-if="item.order_info.order_status==41">已结算</el-tag>
                        </div>
                        <div class="suc_rg">费用：<span style="color:#f54123;font-size:22px;">￥{{item.estimate_amount}}</span></div>
                    </div>
                    <div class="order_content flex_start_center">
                        <div class="order_mileage">
                            <div class="mileage_lf flex_start">
                                <div class="start_mileage flex_start">
                                    <div class="start_icon">装</div>
                                    <div class="column_start" style="flex: 1;">
                                        <span style="line-height: 1.2;">{{item.haulwayList[0].address_name || item.haulwayList[0].address_info}}</span>
                                        <span style="line-height: 1.2;" v-if="item.haulwayList[0].linkman_name">{{item.haulwayList[0].linkman_name}} <span v-if="item.haulwayList[0].linkman_phone">（{{item.haulwayList[0].linkman_phone}}）</span> </span>
                                    </div>
                                    <span class="poin_info" v-if="(item.haulwayList.length - 2) > 0">{{item.haulwayList.length - 2}}途经点</span>
                                </div>
                                <img :src="icons" class="right_icon" alt="">
                                <div class="end_mileage flex_start">
                                    <div class="end_icon">卸</div>
                                    <div class="column_start" style="flex: 1;">
                                        <span style="line-height: 1.2;">{{item.haulwayList[item.haulwayList.length - 1].address_name || item.haulwayList[item.haulwayList.length - 1].address_info}}</span>
                                        <span style="line-height: 1.2;" v-if="item.haulwayList[item.haulwayList.length - 1].linkman_name">{{item.haulwayList[item.haulwayList.length - 1].linkman_name}} <span v-if="item.haulwayList[item.haulwayList.length - 1].linkman_phone">（{{item.haulwayList[item.haulwayList.length - 1].linkman_phone}}）</span> </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mileage_num flex_between_center">
                                <span>小货车 运输里程：{{item.order_info.distance}}公里 <span class="content_tag" @click.stop="toPriceDetail(item)" style="color:#f54123;">价格明细</span> </span>
                                <div class="flex_center_start">
                                    <el-button @click.stop="toPriceDetail(item)" v-if="order_status=='carriage_end'">价格凭证</el-button>
                                    <el-button @click.native="confirmOrder(item,index)" v-if="order_status=='wait_sign'">确认签收</el-button>
                                    <el-button @click.stop="toPayOrder(item)" v-if="order_status=='carriage_end'">支付订单</el-button>
                                    <el-button @click.stop="onCancelOrder(item,index)" v-if="order_status=='wait'">取消订单</el-button>
                                    <el-button @click.native="toCheckOrder(item)">查看订单</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
            <div class="no_list" v-else>暂无数据</div>
        </div>
        <el-pagination background @current-change="onCurrentPage" layout="total, sizes, prev, pager, next, jumper" :total="count" :current-page.sync="page" :page-size="pageSize"></el-pagination>
        <cancel-tips @select="selectTips" @cancel="showCancelTips = false" @confirm="confirmCancel" :show="showCancelTips"></cancel-tips>
    </div>
</template>

<script>
var canPost = true;
import cancelTips from "@/components/cancelTips"
import {handleGetOrderList,handleReceiveGoods} from '@api/orderList'
import {handleCannelOrder} from '@api/matchOrder'
import icons from "@assets/imgs/u912.png"
export default {
    components:{
        cancelTips
    },
    data() {
        return {
                order_navs: [{
                    name: '待接单',
                    status: 'wait',
                    num: 0
                }, {
                    name: '已接单',
                    status: 'shipment',
                    num: 0
                }, {
                    name: '待装货',
                    status: 'reach_address',
                    num: 0
                }, {
                    name: '运输中',
                    status: 'carriage_start',
                    num: 0
                }, {
                    name: '待签收',
                    status: 'wait_sign',
                    num: 0
                }, {
                    name: '已完成',
                    status: 'carriage_end',
                    num: 0
                }, {
                    name: '已结算',
                    status: 'pay',
                    num: 0
                }, {
                    name: '已取消',
                    status: 'cancel',
                    num: 0
                }],
                order_name: '待接单',
                order_status: 'wait',
                orderList: [],
                orderData:null,         //当前选中的订单数据
                order_index:'',             //当前选中的订单下标
                page: 1,
                pageSize: 10,
                showCancelTips:false,
                msg_id:'',          //取消订单原因id
                icons:icons,
                count:0,
        }
    },
    created() {
        let {status = false,name = false} = this.$route.params;
        if(status && name){
            this.order_status = status;
            this.order_name = name;
        }
        if(this.userData == null || !this.userData){
            this.$confirm('请先前往登录', '外运通用车', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'info'
            }).then(() => {
                this.$router.replace('/login')
            }).catch(() => {
            });
        }else{
            this.onGetOrderList()
        }
    },
    mounted() {

    },
    computed:{
        userData:function(){
            return this.$store.getters.userData || this.$storage.get('userData')
        },
    },
    methods: {
        confirmOrder(item,index) {
            let order_sn = item.order_sn
            let isUploadEvidence = item.isUploadEvidence
            if (!isUploadEvidence) {
                this.$notify.warning({
                    title:'提示',
                    message:'签收失败，请下拉刷新或联系司机是否确认代付费用'
                })
                return false
            }
            let datakey = {};
            datakey['order_sn'] = order_sn;
            datakey['user_sn'] = this.userData.user_sn;
            
            this.$confirm('请确认是否已经签收商品?', '签收订单', {
                confirmButtonText: '确认签收',
                cancelButtonText: '我再想想',
                confirmButtonClass: 'confirm_class',
                cancelButtonClass: 'cancel_class',
                type: 'info'
            }).then(() => {
                handleReceiveGoods(datakey).then(datas=>{
                    if(datas.status == 0){
                        this.$message({
                            type: 'success',
                            message: '签收成功!'
                        });
                        this.orderList.splice(index,1);
                        this.$set(this.order_navs[4],'num',this.order_navs[4].num - 1)
                    }else{
                        this.$message.error('签收失败')
                    }
                }).catch(err=>{

                })
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消签收'
                });
            });
        },
        toPriceDetail(data){
            let item = data;
            this.$router.push({name:'PriceDetail',params:{order_sn:item.order_sn,back_car:item.back_car}})
        },
        changeNav(status,name) {
            this.order_status = status;
            this.order_name = name;
            this.page = 1;
            this.onGetOrderList()
        },
        toCheckOrder(data){
            let item = data;
            this.$router.push({name:'CheckOrder',params:{order_sn:item.order_sn}})
        },
        onCurrentPage(evt){
            this.page = evt;
            this.onGetOrderList();
        },
        onCancelOrder(data,index){
            let item = data;
            this.orderData = item;
            this.order_index = index;
            this.showCancelTips = true;
        },
        toPayOrder(data){
            let item = data;
            this.$router.push({name:'PayOrder',params:{orderData:item,order_sn:'20210224142839362104'}})
        },
        // 选择取消订单原因
        selectTips(data){
            let item = data;
            this.msg_id = item.msg_id;
        },
        // 确认取消订单
        confirmCancel(){
            let orderData = this.orderData
            let datakey = {}
            let msg_id = this.msg_id
            if (msg_id == null || msg_id == '') {
                this.$message.warning('请选择取消订单原因')
                return false
            }
            datakey['user_sn'] = this.userData.user_sn
            datakey['order_sn'] = orderData.order_sn
            datakey['msg_id'] = this.msg_id
            handleCannelOrder(datakey).then(datas=>{
                if (datas.status == 0) {
                    // app.globalData.refresh = false
                    this.$confirm('订单取消成功', '提示', {
                        confirmButtonText: '确定',
                        showCancelButton:false,
                        type: 'info'
                    }).then(() => {
                        this.$storage.remove('orderData');
                        this.orderList.splice(this.order_index,1);
                    }).catch(() => {
                    });
                } else {
                    this.$message.error(datsa.msg)
                }
                this.showCancelTips = false;
            }).catch(err=>{
                console.log(err)
            })
        },
        // 获取订单列表
        onGetOrderList(){
            canPost = false
            let datakey = {};
            let page = this.page
            let order_status = this.order_status
            datakey['user_sn'] = this.userData.user_sn
            datakey['page'] = page
            datakey['limit'] = 10
            datakey['order_status'] = order_status
            handleGetOrderList(datakey).then(datas=>{
                canPost = true
                console.log(datas)
                if(typeof datas == 'string'){
                    datas = datas.replace("370","")
                    datas = JSON.parse(datas)
                }
                if (datas.status == 0) {
                    let pageCount = datas.data.pageCount
                    let newList = datas.data.orderList
                    // if (newList.length == 0 && page == 1 && order_status == 'wait') {
                    //     wx.removeStorageSync('orderData')
                    // }
                    let statusCount = datas.data.statusCount
                    let order_navs = this.order_navs
                    order_navs.forEach(item => {
                        if (item.status == 'wait') {
                            item.num = statusCount.wait_total
                        } else if (item.status == 'shipment') {
                            item.num = statusCount.shipment_total
                        } else if (item.status == 'reach_address') {
                            item.num = statusCount.reach_address_total
                        } else if (item.status == 'carriage_start') {
                            item.num = statusCount.carriage_total
                        } else if (item.status == 'wait_sign') {
                            item.num = statusCount.wait_sign_total
                        } else if (item.status == 'carriage_end') {
                            item.num = statusCount.finish_total
                        } else if (item.status == 'pay') {
                            item.num = statusCount.pay_total
                        } else if (item.status == 'cancel') {
                            item.num = statusCount.cancel_total
                        }
                    });
                    this.orderList = newList;
                    this.order_navs = order_navs;
                    this.count = datas.data.count;
                } else {
                    this.orderList = [];
                }
            }).catch(err=>{

            })
        }
    },
    filters:{
        timestampToTime: function(timestamp) {
            if (timestamp != undefined) {
                var date = new Date(timestamp * 1000)
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return `${Y}-${M}-${D} ${h}:${m}`;
            }
        }
    }
}
</script>

<style lang="scss">
    .order_wrap {
        height: 100%;
        .order_nav {
            width: 100%;
            margin: 16px 0;
        }
        
        .order_nav_item {
            color: #333;
            font-size: 14px;
            flex: 1;
            text-align: center;
            line-height: 1.3;
            cursor: pointer;
            position: relative;
        }
        
        .order_nav_item:hover {
            color: #02a7f0;
        }
        
        .order_nav_active {
            color: #02a7f0;
        }
        
        .order_nav_active::after {
            content: "";
            position: absolute;
            height: 2px;
            width: 60%;
            bottom: -10px;
            left: 0;
            right: 0;
            margin: auto;
            background: #02a7f0;
            transition: all .3s ease-in-out;
        }
        
        .order_list {
            flex: 1 1;
            width: 100%;
            padding: 20px;
            overflow: hidden;
            overflow-y: auto;
        }
        
        .el-pagination {
            margin: 10px auto;
        }
        
        .order_info {
            width: 100%;
            border-radius: 10px;
            border: 1px solid #ddd;
            padding: 0 20px;
            margin-bottom: 20px;
        }
        
        .order_info:last-child {
            margin-bottom: 0;
        }
        
        .order_content {
            padding: 20px 0;
        }
        
        .order_title {
            height: 50px;
            border-bottom: 1px dashed #ddd;
        }
        
        .suc_lf {
            font-size: 15px;
            .el-tag{
                margin-left: 6px;
            }
        }
        
        .order_mileage {
            flex: 1 1;
        }
        
        .mileage_lf {
            position: relative;
            width: max-content;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: -ms-max-content;
            width: -o-max-content;
            margin-bottom: 12px;
        }
        
        .start_mileage {
            position: relative;
            margin-right: 10px;
        }
        
        .poin_info {
            position: absolute;
            right: -66px;
            top: -6px;
            // margin: auto;
            font-size: 13px;
            color: #333;
            text-align: center;
            width: max-content;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: -ms-max-content;
            width: -o-max-content;
            // padding-right: 24px;
        }
        
        .start_icon,
        .end_icon {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            font-size: 14px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            color: #fff;
            margin-right: 20px;
        }
        
        .start_icon {
            background: #299ede;
        }
        
        .end_icon {
            background: #f54123;
        }
        
        .confirm_class {
            background: #02a7f0;
            color: #fff;
        }
        
        .cancel_class {
            background: #d7d7d7;
            color: #333;
        }
        .content_tag{
            border: 1px solid #ddd;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            padding: 0 5px;
            cursor: pointer;
        }
    }
    
    
</style>